import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import ActionType from '../store/action-type';
import usePathName from './use-path-name';

const useLocale = () => {
  const pathName = usePathName();
  const currentLocale = useSelector((state) => state.locale.picked);
  const dispatch = useDispatch();
  return (locale) => {
    if (locale === currentLocale) return;
    dispatch({ type: ActionType.Locale.Set, payload: locale });
    navigate(`/${locale}/${pathName}`);
  };
};

export default useLocale;
